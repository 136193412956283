  <template>
  <div class="uk-container full-page">
    <UserHeader />
    <div class="uk-margin-bottom">
      <h1>Customers</h1>
      <div uk-grid class="uk-margin-bottom-small">
        <div class="uk-width-1-4">
          <div class="uk-position-relative">
            <input v-model="searchTermCustomer" @keyup.enter="fetchItems" placeholder="Email or Last Name" class="uk-input" />
            <button v-if="searchTermCustomer" @click="clearSearchCustomer" class="clear-button">×</button>
          </div>
        </div>
        <div class="uk-width-1-4">
          <div class="uk-position-relative">
            <input v-model="searchTermKit" @keyup.enter="fetchItems" placeholder="Kit Id" class="uk-input" />
            <button v-if="searchTermKit" @click="clearSearchKit" class="clear-button">×</button>
          </div>
        </div>
        <div class="uk-flex uk-flex-middle uk-flex-between test-results-nav">
          <ul uk-tab>
            <li :class="{ 'uk-active': view === 'default' }" @click="changeView('default')" uk-tooltip="Account Created"><a href="#">Account Created</a></li>
            <li :class="{ 'uk-active': view === 'registered' }" @click="changeView('registered')" uk-tooltip="Registered"><a href="#">Registered</a></li>
            <li :class="{ 'uk-active': view === 'received' }" @click="changeView('received')" uk-tooltip="Received"><a href="#">Received</a></li>
            <li :class="{ 'uk-active': view === 'uploaded' }" @click="changeView('uploaded')" uk-tooltip="Uploaded"><a href="#">Uploaded</a></li>
          </ul>
        </div>
      </div>
      <div v-if="loading" class="loading-spinner">
        <div uk-spinner></div>
      </div>
      <table v-if="!loading && items && items.length">
        <thead>
          <tr>
            <th>Email / Customer Id / Active</th>
            <th>Kit Id / Serial Number / Kit Type</th>
            <th>Name / Pet Type</th>
            <th>Account Created</th>
            <th>Kit Registered</th>
            <th>Sample Received</th>
            <th>Result Uploaded</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.Id">
            <td>
              <b>
                <span 
                  :uk-tooltip="item.Email"
                  v-text="truncateEmail(item.Email)">
                </span>              
              </b><br/>{{ item.Id }}
              <div class="customers-pet-type">
                <span class="active-inactive">
                  <img 
                    :src="item.Active ? '/images/active.svg' : '/images/inactive.svg'" :uk-tooltip="item.Active ? 'Email Verified' : 'Email Not Verified'"
                  >
                </span>
              </div>
            </td>            
            <td class="nowrap">
              <div class="customers-pet-type">
                <span v-if="item.SerialNumber">
                  {{ item.SerialNumber }}
                </span>
                <span :class="item.TestKitType">
                {{ item.TestKitType }}
                </span>
                <span v-if="item.KitId">
                  <br/>{{ item.KitId }}
                </span>
              </div>
            </td>            
            <td>
              {{ item.FirstName || (item.PetName) }} {{ item.LastName }} 
              <span v-if="item.PetType === 'Cat' || item.PetType === 'Dog'">
                <br/>({{ item.PetType }})
              </span>
            </td>
            <td class="nowrap">
              <span v-if="item.CreatedOnUtc">
                {{ convertUtcToLocal(item.CreatedOnUtc) }}
              </span>
            </td>
            <td class="nowrap">
              <span v-if="item.RegisteredOnUtc">
                {{ convertUtcToLocal(item.RegisteredOnUtc) }}
              </span>
            </td>
            <td class="nowrap">
              <span v-if="item.ReceivedDateUtc">
                {{ convertUtcToLocal(item.ReceivedDateUtc) }}
              </span>
            </td>
            <td class="nowrap">
              <span v-if="item.ResultDateUtc">
                {{ convertUtcToLocal(item.ResultDateUtc) }}
              </span>
            </td>
            <td class="nowrap">
              <span v-if="item.ResultDateUtc">
                <a :href="`/test-results?id=${item.KitId}`" class="uk-margin-right-small" target="_blank">
                  <img 
                    src="/images/report.svg" 
                    uk-tooltip="View Results" 
                  />
                </a>
              </span>
              <img 
                src="/images/activities.svg" 
                uk-toggle="target: #set-activities-modal"
                uk-tooltip="Show Activities" 
                @click="selectCustomer(item)" 
                class="action-icon uk-margin-right-small"
                />
              <img 
                src="/images/impersonate.svg" 
                uk-toggle="target: #impersonate-user"
                uk-tooltip="Impersonate User" 
                @click="handleImpersonateClick(item)" 
                class="action-icon uk-margin-right-small"
              />
              <img 
                src="/images/password.svg" 
                uk-toggle="target: #set-password-modal"
                uk-tooltip="Set Password" 
                @click="selectCustomer(item)" 
                class="action-icon uk-margin-right-small"
                />
              <img 
                src="/images/email.svg" 
                uk-toggle="target: #update-email-modal"
                uk-tooltip="Update Email" 
                @click="selectCustomer(item)" 
                class="action-icon uk-margin-right-small"
                />
              <span v-if="!item.Active">
                <img 
                  src="/images/resend.svg" 
                  uk-toggle="target: #set-resend-modal"
                  uk-tooltip="Resend Email Verification Link"
                  @click="selectCustomer(item)" 
                  class="action-icon uk-margin-right-small"
                  />
                <img 
                  src="/images/verify-email.svg" 
                  uk-toggle="target: #verify-email-modal"
                  uk-tooltip="Verify Email"
                  @click="selectCustomer(item)" 
                  class="action-icon uk-margin-right-small"
                  />
                </span>
            </td>            
          </tr>
        </tbody>
      </table>
      <div v-if="totalPages && totalPages > 1" class="pagination-controls">
        <button class="uk-button uk-button-secondary uk-margin-right" @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <button class="uk-button uk-button-secondary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
      <div v-if="totalPages && totalPages > 1" class="uk-text-small uk-margin-top">
        Page {{ currentPage }} of {{ formattedTotalPages }} ({{formattedTotalItems}} records)
      </div>
    </div>
  </div>
  <SetPasswordModal v-if="selectedCustomer" :customer="selectedCustomer" />
  <UpdateEmailModal v-if="selectedCustomer" :customer="selectedCustomer" />
  <CustomerActivitiesModal v-if="selectedCustomer" :customer="selectedCustomer" />
  <ResendEmailVerificationEmailModal v-if="selectedCustomer" :customer="selectedCustomer" />
  <VerifyEmailModal v-if="selectedCustomer" :customer="selectedCustomer" />
</template>

<script>
import axios from 'axios';
import UserHeader from '@/components/UserHeader.vue'; 
import SetPasswordModal from '@/components/SetPasswordModal';
import UpdateEmailModal from '@/components/UpdateEmailModal';
import CustomerActivitiesModal from '@/components/CustomerActivitiesModal';
import ResendEmailVerificationEmailModal from '@/components/ResendEmailVerificationEmailModal';
import VerifyEmailModal from '@/components/VerifyEmailModal';
import { convertUtcToLocal } from '@/utils/common';

export default {
  created() {
    document.title = 'Customers | Admin | UCARI';
    this.accessToken = localStorage.getItem('adminAccessToken');
    this.cid = localStorage.getItem('adminCid');
  },
  components: {
    UserHeader,
    SetPasswordModal,
    UpdateEmailModal,
    CustomerActivitiesModal,
    ResendEmailVerificationEmailModal,
    VerifyEmailModal   
  },
  data() {
    return {
      items: [],
      selectedCustomer: null,
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      view: 'default',
      searchTerm: '',
      searchTermCustomer: '',
      searchTermKit: '',
      isAuthenticated: false,
      cid: '',
      accessToken: '',
      loading: false
    };
  },
  methods: {
    async fetchItems(filter = 'default') {
      this.loading = true;

      const config = {
        headers: {
          'Authorization': `Bearer ${this.accessToken}`,
          'cid': this.cid
        },
        params: {
          page: this.currentPage || 1,
          limit: 20,
          searchCustomer: this.searchTermCustomer || '',
          searchKit: this.searchTermKit || '',
          filter: filter
        }
      };

      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/admin/customers`, config);
        
        const { items, currentPage, totalPages, totalItems } = response.data;

        this.items = items;
        this.currentPage = currentPage;
        this.totalPages = totalPages;
        this.totalItems = totalItems;
      } catch (error) {
        const errorMessage = error.response ? error.response.data : error.message;
        console.error('Error fetching items:', errorMessage);
        
        this.errorMessage = `Failed to fetch items: ${errorMessage}`;
      } finally {
        this.loading = false;
      }
    },
    changeView(view) {
      this.view = view;
      this.fetchItems(view);
    },
    async impersonateUser(customer) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/admin/impersonate`, {
          adminGuid: this.cid,
          customerId: customer.Id,
          customerEmail: customer.Email,
          customerGuid: customer.CustomerGuid
        }, {
          headers: {
            'Authorization': `Bearer ${this.accessToken}`,
            'cid': this.cid
          },
        });

        const token = response.data.token;
        const customerGuid = customer.CustomerGuid;

        if (token && customerGuid) {
          window.open(`https://account.ucari.com?t=${token}&c=${customerGuid}`, '_blank');
        }

      } catch (error) {
        console.error('Failed to impersonate user:', error);
      }
    },
    handleImpersonateClick(item) {
      this.selectCustomer(item);
      this.impersonateUser(item);
    },
    truncateEmail(email) {
      return email.length > 20 ? email.slice(0, 20) + '...' : email;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchItems();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchItems();
      }
    },
    clearSearchCustomer() {
      this.searchTermCustomer = '';
      this.fetchItems();
    },
    clearSearchKit() {
      this.searchTermCustomer = '';
      this.fetchItems();
    },
    selectCustomer(customer) {
      this.selectedCustomer = customer;
    },
    convertUtcToLocal
  },
  computed: {
    formattedTotalPages() {
      return this.totalPages.toLocaleString();
    },
    formattedTotalItems() {
      return this.totalItems.toLocaleString();
    }
  },
  mounted() {
    this.fetchItems();
  }
};
</script>
